




















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class AuthHeader extends Vue {
  @Prop(String) title!: string
}
