<template>
  <div class="privacy-policy-content">
    <header>
      <p>De Privacyverklaring onder de noemer “Privacyverklaring – Voor Kinderen” is eenniet rechtsgeldige, versimpelde versie van de Privacyverklaring.. Aan deze versievan de Privacyverklaring zijn geen rechten te ontlenen, en deze bevatten eenvoudige tekst om de voorwaarden leesbaar te maken voor kinderen. Deze Privacyverklaring is bedoeld voor informatieve doeleinden.</p>
    </header>

    <section>
      <h2>Privacyverklaring - voor Kinderen</h2>
      <p>In een Privacyverklaring vind je alle informatie over wat een maker, website of dienst met jouw persoonlijke gegevens doet. Wil je ons bereiken dan kan dat door een bericht te sturen via ons <a href="https://www.hollandskroon.nl/contactformulier" target="_blank">contactformulier</a>. Dit is wat we van je bijhouden, waarom en hoe;</p>
      <ul>
        <li><p>Wij verwerken data over jou omdat je het spel Operatie Z.U.I.V.E.R. speelt.</p></li>
        <li><p>Wij verzamelen geen data over het gebruik van de app om door te verkopen aan andere mensen.</p></li>
        <li><p>Wij kunnen alleen jouw gebruikersnaam zien, niet je wachtwoord.</p></li>
        <li><p>Wij houden je e-mailadres bij als je daarvoor gekozen hebt, en alleen als jedaar toestemming van een ouder/verzorger voor hebt.</p></li>
        <li><p>We hebben een e-mailadres nodig om je account aan te maken en om contact met je op te nemen als je bijvoorbeeld iets hebt gewonnen.</p></li>
        <li><p>We houden algemene data bij over hoe alle spelers de game spelen om degame daarmee beter te maken.</p></li>
        <li><p>Heb je onze cookiewaarschuwing gelezen? We houden ook cookies bij om te kijken hoe jij de website gebruikt, zodat we de website beter kunnen maken.</p></li>
        <li><p>Je kunt altijd vragen welke data we van jou bijhouden. Om dit te doen moet je een bericht sturen via ons <a href="https://www.hollandskroon.nl/contactformulier" target="_blank">contactformulier</a>, met daarin je Spelers naam, en een kopie van je geldige identiteitsbewijs (zonder BSN, pasfoto, en letter - en nummerstrook natuurlijk!).</p></li>
        <li><p>Als je wilt kan de gemeente Hollands Kroon al jouw data weggooien. Je raakt dan wel je account en al je punten kwijt. Om dit te doen moet je een email sturen naar <a href="https://www.hollandskroon.nl/contactformulier" target="_blank">contactformulier</a>, met daarin je Spelers ID naam, en eenkopie van je geldige identiteitsbewijs (zonder BSN, pasfoto, en letter- en nummerstrook natuurlijk!).</p></li>
        <li><p>Wij doen er alles aan, met bijvoorbeeld encryptie, om jouw data veilig en goed op te slaan. Maar je mag altijd een e-mail sturen of een klacht indienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens.</p></li>
      </ul>
    </section>

    <section>
      <h2>Privacyverklaring - voor Volwassenen</h2>
      <p>De gemeente Hollands is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring. Contactgegevens: <a href="https://www.hollandskroon.nl" target="_blank">www.hollandskroon.nl</a>. Postbus 8, 1760 AA Anna Paulowna. 088-321 5000.</p>

      <p>De gemeente Hollands Kroon verwerkt je persoonsgegevens omdat je gebruik maakt van onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt.</p>

      <p>Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:E-mailadres, naam van de school, naam van stad of drop, locatiegegevens afval, foto's van zwerfafval. Overige persoonsgegevens die je actief verstrekt in bijvoorbeeld correspondentie en telefonisch.</p>

      <p>De gemeente Hollands Kroon verwerkt de volgende bijzondere en/of gevoelige persoonsgegevens van jou: - gegevens van personen jonger dan 16 jaar.</p>

      <p>Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar, tenzij spelers/gebruikers van 16 jaar of jonger toestemming hebben van ouders/verzorger of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is, en er wordt geen persoonlijke data opgeslagen.</p>

      <p>Wij raden ouders aan betrokken te zijn bij de online-activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld wordenzonder ouderlijke toestemming.</p>

      <p>Als je ervan overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons via ons <a href="https://www.hollandskroon.nl/contactformulier" target="_blank">contactformulier</a>, dan verwijderen wij deze informatie.</p>

      <p>De gemeente Hollands Kroon verwerkt jouw persoonsgegevens voor de volgendedoelen:</p>

      <ul>
        <li><p>Om je te kunnen e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren;</p></li>
        <li><p>Om contact met jou of met je school op te nemen over prijzen;</p></li>
        <li><p>Om je de mogelijkheid te bieden een account aan te maken;</p></li>
        <li><p>Om de aangeboden dienst te verbeteren en storingen te kunnen verhelpen;</p></li>
        <li><p>Analyse van locaties waar veel zwerfafval wordt gevonden.</p></li>
      </ul>

      <p>De gemeente Hollands Kroon neemt op basis van geautomatiseerde verwerkingen geen besluiten over zaken die (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die worden genomen door computerprogramma's of -systemen, zonder dat daar een mens (bijvoorbeeld een medewerker van de gemeente) tussen zit.</p>

      <p>De gemeente Hollands Kroon behoudt zich voor een account van een speler/gebruiker te verwijderen of op non-actief te stellen als hier gedegen aanleiding (bijvoorbeeld een racistische of kwetsende gebruikersnaam of cyberpesten) toe is. Hier is geen correspondentie vanuit De gemeente Hollands Kroon voor nodig.</p>

      <p>De gemeente Hollands Kroon bewaart je persoonsgegevens zolang er gebruik gemaakt wordt van een gepersonaliseerd account. Op verzoek kunnen gegevensen accounts worden verwijderd.</p>

      <p>De gemeente Hollands Kroon verkoopt jouw gegevens niet aan derden en zal deze uitsluitend verstrekken indien dit nodig is voor de uitvoering van onze overeenkomst met jou of om te voldoen aan een wettelijke verplichting. Met bedrijven die jouw gegevens verwerken in onze opdracht, sluiten wij een bewerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van jouw gegevens. De gemeente Hollands Kroon blijft verantwoordelijk voor deze verwerkingen.</p>

      <p>De Operatie Z.U.I.V.E.R. app deelt geen gegevens met derden met uitzondering tot <a href="https://firebase.google.com/terms/data-processing-terms" target="_blank">Google</a> en <a href="https://legal.branch.io/#branchio-privacypolicy" target="_blank">Branch</a> t.b.v. analyse van app gebruik. Deze informatie wordt anoniem gedeeld en is niet te herleiden naar de gebruiker.</p>

      <p>De gemeente Hollands Kroon gebruikt alleen technische en functionele cookies, en analytische cookies die geen inbreuk maken op je privacy. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen op jouw computer, tablet of smartphone. De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website en jouw gebruiksgemak.Ze zorgen ervoor dat de website naar behoren werkt en onthouden bijvoorbeeld jouw voorkeursinstellingen. Ook kunnen wij hiermee onze website optimaliseren. Je kunt je afmelden voor cookies door je internetbrowser zo in te stellen dat dezegeen cookies meer opslaat. Daarnaast kun je ook alle informatie die eerder is opgeslagen via de instellingen van je browser verwijderen.</p>

      <p>Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking vanjouw persoonsgegevens door De gemeente Hollands Kroon en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een <a href="http://www.hollandskroon.nl/user/login/authenticate?method=digid&destination=/info/verzoek-rechten-betrokkenen-avg" target="_blank">inzageverzoek</a> kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand naar jou of een ander, door jou genoemde organisatie, te sturen. Het veranderen of wijzigen van je persoonsgegevens kan betekenen dat je geen aanspraak meer kan maken op de volledige functionaliteit van Operatie Z.U.I.V.E.R. of aanverwante activiteiten. Hieraan verwante verloren data kan nietmeer terug worden gehaald.</p>

      <p>Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou een kopie van je identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie je pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart.Dit ter bescherming van je privacy. We reageren zo snel mogelijk, maar binnen vier weken, op jouw verzoek.</p>

      <p>De gemeente Hollands Kroon wil je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: <a href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons" target="_blank">https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons</a>.</p>

      <p>De gemeente Hollands Kroon neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op via ons <a href="https://www.hollandskroon.nl/contactformulier" target="_blank">contactformulier</a>.</p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicyContent',
}
</script>

<style lang="scss" scoped>
p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

h2 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

li > p {
  margin-top: 0;
  margin-bottom: 0;
}

ul {
  list-style-type: disc;
  padding-left: 0.9rem;
  padding-right: 0.2rem;
}
</style>
