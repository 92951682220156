














import { Component, Vue } from 'vue-property-decorator'
import PrivacyPolicyContent from '@/components/elements/PrivacyPolicyContent.vue'
import AuthHeader from '@/components/elements/AuthHeader.vue'

@Component({
  components: { PrivacyPolicyContent, AuthHeader },
})
export default class PrivacyPolicy extends Vue {

}
